<template>
  <div class="home">
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld
  }
};
</script>

<style scoped>
.home {
  box-sizing: border-box;
  width: 50%;
  padding: 0 20px 20px;
  margin: 0 auto;
  max-width: 600px;
}
</style>
